import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import './static/base.less'

Vue.config.productionTip = false

Vue.prototype.readFile = function(filePath){
  let xhr = null, okStatus = document.location.protocol === 'file' ? 0 : 200
             xhr = new XMLHttpRequest() 
             xhr.open('GET', filePath, false)
             xhr.overrideMimeType('text/html;charset=utf-8')
             xhr.send(null)
             return xhr.status === okStatus ? xhr.responseText : null
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
