import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/i',
        name: 'Investment',
        component: () => import('../views/i/Index.vue'),
        redirect: '/i/home',
        children: [{
            path: 'home',
            name: 'IHome',
            component: () => import('../views/i/Home.vue'),
            meta: {
                keepAlive: true
            }
        },
            {
                path: 'about',
                name: 'IAbout',
                component: () => import('../views/i/About.vue')
            },
            {
                path: 'kdAbout',
                name: 'KDAbout',
                component: () => import('../views/i/KDAbout.vue')
            },
            {
                path: 'detail/:id',
                name: 'Detail',
                component: () => import('../views/i/Detail.vue'),
                props: true
            },
            {
                path: 'qa',
                name: 'iQA',
                component: () => import('../views/i/QA.vue'),
                meta: {
                    keepAlive: true
                }
            },
            {
                path: 'product',
                name: 'Product',
                component: () => import('../views/i/Product.vue'),
                meta: {
                    keepAlive: true
                }
            },
            {
                path: 'tacx',
                name: 'TalkAboutCX',
                component: () => import('../views/i/TACX.vue'),
                meta: {
                    keepAlive: true
                }
            },
            {
                path: 'takd',
                name: 'TalkAboutKD',
                component: () => import('../views/i/TAKD.vue'),
                meta: {
                    keepAlive: true
                }
            }]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
